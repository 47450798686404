import { apiURL, baseURL, bulkOpsUrl } from 'config/baseURL';
import API from 'helpers/api';
import { downloadArrayBuffer, downloadURL } from 'utils/download';
import { queryStringify } from 'utils/router';
import moment from 'moment';
import { toast } from 'react-toastify';

export const getInternalToken = async (token: string): Promise<string> => {
	const url = `${apiURL}/auth/retrieve-jwt?token=${token}`;
	const request = new Request(url);
	const response = await fetch(request);
	if (!response.ok) return '';
	const result = await response.json();
	return (result as { data: string }).data;
};

export const downloadTemplate = async (payload: any, isValidationOnly: boolean): Promise<any> => {
	try {
		const query = JSON.stringify({
			entities: payload.entities ?? null,
			questionnaireIds: payload.entities.includes('questionnaires') ? payload.questionnaireID ?? null : null,
			startDate: payload.entities.includes('schedules') ? payload.startDate ?? null : null,
			endDate: payload.entities.includes('schedules') ? payload.endDate ?? null : null,
			isValidationOnly: isValidationOnly,
			scheduleOptions: payload.entities.includes('schedules') ? payload.scheduleOptions ?? null : null,
		});
		const authToken = await API.getFirebaseToken();
		const options = {
			method: 'POST',
			headers: {
				Authorization: await getInternalToken(authToken),
				'Content-Type': 'application/json',
			},
			body: query,
		};

		const url = `${bulkOpsUrl}/bulk-edit/download`;
		const response = await fetch(url, options);
		const responseJson = await response.json();

		if (isValidationOnly) {
			return responseJson;
		} else {
			if (response.status !== 200) {
				return responseJson;
			}
			const responseData = await responseJson.data;
			if (responseData) {
				downloadURL(responseData, `nimbly-bulk-edit-template-${moment().format('DD-MM-YY')}.xlsx`);
				return response;
			}
		}
	} catch (error) {
		toast.error(error);
	}
};
